import React, { useEffect } from 'react';
import { PageProps, graphql, useStaticQuery, navigate } from 'gatsby';
import { Parallax, Background } from 'react-parallax';
import { useTranslation } from 'react-i18next';
import { useMst } from '@/models/root-store';

import { Carousel } from '../components';
import {
  SpaceBetweenContent,
  SPACE_BETWEEN_CONTENT,
} from '../Layouts/styled/main';
import EndingContent from '../Layouts/Footer/ending-content';
import ProductHighlight from '../components/card-product/product-highlight';
import HerbHightlight from '../components/card-product/herb-hightlight';

interface IndexProps extends PageProps {
  data: any;
}

const Home: React.FC<IndexProps> = ({ data }) => {
  const { t } = useTranslation();
  const { ouayUnStore } = useMst();

  const onClick = (response: any) => {
    let productCode = '';
    if (
      response.imageName === 'main-slide-1.jpg' ||
      response.imageName === 'main-slide-1m.jpg'
    ) {
      productCode = '3005';
    } else if (
      response.imageName === 'main-slide-2.jpg' ||
      response.imageName === 'main-slide-2m.jpg'
    ) {
      productCode = '3008';
    } else if (
      response.imageName === 'main-slide-3.jpg' ||
      response.imageName === 'main-slide-3m.jpg'
    ) {
      productCode = '3008';
    } else if (
      response.imageName === 'main-slide-4.jpg' ||
      response.imageName === 'main-slide-4m.jpg'
    ) {
      productCode = '4012';
    } else if (
      response.imageName === 'main-slide-5.jpg' ||
      response.imageName === 'main-slide-5m.jpg'
    ) {
      productCode = '2051';
    } else if (
      response.imageName === 'main-slide-6.jpg' ||
      response.imageName === 'main-slide-6m.jpg'
    ) {
      productCode = '2003';
    } else if (
      response.imageName === 'main-slide-7.jpg' ||
      response.imageName === 'main-slide-7m.jpg'
    ) {
      productCode = '2059';
    }
    navigate(`/product/${productCode}`);
  };

  return (
    <main>
      {/* //? section 1 */}
      <Carousel
        speed={4000}
        dataSource={data}
        imageHeight={ouayUnStore?.screenSize?.width}
        onClick={onClick}
      />

      {/* //? section 2 */}
      <div style={{ paddingTop: 60 }} className="d-md-none d-block" />
      <div style={SPACE_BETWEEN_CONTENT} className="d-none d-md-block" />
      <ProductHighlight data={data?.products.edges} />

      {/* //? section 3 */}
      <SpaceBetweenContent />
      <HerbHightlight data={data} />

      {/* //? section 4 */}
      <SpaceBetweenContent />
      <EndingContent />

      <SpaceBetweenContent className="d-md-none d-block" />

      {/* <Parallax
        blur={{ min: -15, max: 15 }}
        bgImage={images.womenSlide1}
        bgImageAlt="the dog"
        strength={-200}
      >
        <div style={{ height: '900px' }} />
      </Parallax> */}
    </main>
  );
};

export default Home;

export const query = graphql`
  query {
    mobileImage: allFile(
      filter: { relativePath: { regex: "/slide/mobile/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 355, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    desktopImage: allFile(
      filter: { extension: { eq: "jpg" }, relativeDirectory: { eq: "slide" } }
      sort: { fields: childImageSharp___fluid___originalName, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1050, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    herbOfMonth: allFile(filter: { relativePath: { regex: "^/main/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    noImage: file(relativePath: { eq: "product/no-img.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    products: allFile(filter: { relativePath: { regex: "/product/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 335, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
