import React, { CSSProperties, Fragment, useEffect, useState } from 'react';
import { palette, color, images, spacing } from '../../Layouts/theme';
import { CardProduct } from './card-product';
import { Container, Row, Col } from 'react-bootstrap';
import {
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_TH,
  HEADER_EN,
  BODY_2_TH,
  BODY_2_EN,
  NON_PADDING_LEFT_RIGHT,
  MOBILE_CONTENT_CARD,
  CONTENT_CARD,
  BUTTON_STYLE,
} from '../../Layouts/styled/main';
import { useTranslation } from 'react-i18next';
import { navigate, Link } from 'gatsby';
import i18next from 'i18next';
import { products, brands } from '../../db/products';
import { Parallax } from 'react-scroll-parallax';
import { getSourceImage } from '@/utils/image-sharp';

const LINK_TO: CSSProperties = {
  textDecoration: 'none',
  color: color.textBlack,
};

interface ProductHighlightProps {
  data?: any;
}

const ProductHighlight: React.FC<ProductHighlightProps> = ({ data }) => {
  const { t } = useTranslation();
  const [product, setProduct] = useState<any>({});
  const [selectedMenuName, setSelectedMenuName] = useState<string | null>();

  useEffect(() => {
    const mainProducts = products.filter(
      (product: any) => +product.product_highLight === 1,
    );

    const currentIndex = mainProducts?.length;
    const randomIndex = Math.floor(Math.random() * currentIndex);
    const prod = mainProducts[randomIndex];
    setProduct(prod);

    const menu = brands.find((attr: any) => attr.id === prod?.brand_id);
    setSelectedMenuName(
      i18next?.language === 'th' ? menu?.name_th : menu?.name_en,
    );
  }, []);

  const BUTTON: CSSProperties = {
    ...BUTTON_STYLE,
    fontFamily: i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular',
  };

  return (
    <Fragment>
      <section className="is-not-hd-size">
        <Container fluid>
          <Row className="justify-content-center">
            {/* //? for screen size xs-md */}
            <Col xs="12" className="d-md-none d-block">
              <Link
                to={`/product/${product?.product_code}`}
                style={LINK_TO}
                state={{
                  productType: selectedMenuName,
                }}
              >
                <CardProduct
                  source={getSourceImage(
                    product?.product_image_path_1
                      ? product.product_image_path_1
                      : 'no-img.png',
                    data,
                  )}
                  containerStyle={{
                    borderRadius: '0px 80px',
                    background: product?.theme_code
                      ? `linear-gradient(180deg, ${palette.productSet1[`${product.theme_code}1`]
                      } 60%, ${palette.productSet1[`${product.theme_code}2`]
                      } 40%)`
                      : 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
                    paddingTop: 0,
                    overflow: 'hidden',
                  }}
                  containerImageStyle={{
                    maxWidth: '300px',
                  }}
                />
              </Link>
            </Col>
            <Col xs="10" className="d-md-none d-block">
              <Container
                fluid
                style={{ paddingTop: 40, alignContent: 'center' }}
              >
                <Row>
                  <Col xs="12" className="text-spacing-l-r">
                    <div data-aos="fade-up">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            i18next?.language === 'th'
                              ? product?.product_name_th
                              : product?.product_name_en,
                        }}
                        style={
                          i18next?.language === 'th'
                            ? MOBILE_HEADER_TH
                            : MOBILE_HEADER_EN
                        }
                      />
                      <br />
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            i18next?.language === 'th'
                              ? product?.details_paragraph_1_th
                              : product?.details_paragraph_1_en,
                        }}
                        style={
                          i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xs="auto" className="text-spacing-l-r">
                    <button
                      style={BUTTON}
                      onClick={() =>
                        navigate(`/product/${product?.product_code}`)
                      }
                    >
                      {t('more_detail')}
                    </button>
                  </Col>
                </Row>
              </Container>
            </Col>

            {/* //? for screen size md++ */}
            <Col
              md="6"
              style={{ paddingLeft: spacing[0] }}
              className="d-none d-md-block"
            >
              <Link
                to={`/product/${product?.product_code}`}
                style={LINK_TO}
                state={{
                  productType: selectedMenuName,
                }}
              >
                <CardProduct
                  source={getSourceImage(
                    product?.product_image_path_1
                      ? product.product_image_path_1
                      : 'no-img.png',
                    data,
                  )}
                  containerStyle={{
                    borderRadius: '0px 110px',
                    background: product?.theme_code
                      ? `linear-gradient(180deg, ${palette.productSet1[`${product.theme_code}1`]
                      } 60%, ${palette.productSet1[`${product.theme_code}2`]
                      } 40%)`
                      : 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
                  }}
                  containerImageStyle={{
                    maxHeight: 350,
                    maxWidth: 350
                  }}
                />
              </Link>
            </Col>
            <Col xs="1" className="d-none d-md-block" />
            <Col xs="10" md="4" className="d-none d-md-block">
              <Container
                fluid
                style={{
                  ...CONTENT_CARD,
                  paddingBottom: spacing[0],
                  alignContent: 'center',
                  height: '100%',
                }}
              >
                <Row>
                  <Col xs="12">
                    <div data-aos="fade-up">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            i18next?.language === 'th'
                              ? product?.product_name_th
                              : product?.product_name_en,
                        }}
                        style={
                          i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                        }
                      />
                      <br />
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            i18next?.language === 'th'
                              ? product?.details_paragraph_1_th
                              : product?.details_paragraph_1_en,
                        }}
                        style={
                          i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xs="auto">
                    <button
                      style={BUTTON}
                      onClick={() =>
                        navigate(`/product/${product?.product_code}`)
                      }
                    >
                      {t('more_detail')}
                    </button>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs="1" className="d-none d-md-block" />
          </Row>
        </Container>
      </section>
      <section className="hd-size">
        <Container fluid>
          <Row className="justify-content-center">
            <div style={{ position: 'absolute', opacity: 0.5, paddingTop: 80 }}>
              <Parallax
                className="custom-class"
                y={[-22, 22]}
                tagOuter="figure"
              >
                <img src={images.flower} width={300} height={'auto'} />
              </Parallax>
            </div>

            <Col xs="auto" data-aos="fade-left" style={{ width: 480 }}>
              <Link
                to={`/product/${product?.product_code}`}
                style={LINK_TO}
                state={{
                  productType: selectedMenuName,
                }}
              >
                <CardProduct
                  source={getSourceImage(
                    product?.product_image_path_1
                      ? product.product_image_path_1
                      : 'no-img.png',
                    data,
                  )}
                  containerStyle={{
                    borderRadius: '0px 110px',
                    background: product?.theme_code
                      ? `linear-gradient(180deg, ${palette.productSet1[`${product.theme_code}1`]
                      } 60%, ${palette.productSet1[`${product.theme_code}2`]
                      } 40%)`
                      : 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
                  }}
                />
              </Link>
            </Col>
            <Col xs="auto" style={{ width: 120 }} />
            <Col xs="auto" style={{ width: 480 }}>
              <Container
                fluid
                style={{
                  paddingTop: spacing[7],
                  alignContent: 'center',
                  height: '100%',
                }}
              >
                <Row>
                  <Col
                    xs="auto"
                    style={{ ...NON_PADDING_LEFT_RIGHT, width: 360 }}
                  >
                    <div data-aos="fade-up">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            i18next?.language === 'th'
                              ? product?.product_name_th
                              : product?.product_name_en,
                        }}
                        style={
                          i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                        }
                      />
                      <br />
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            i18next?.language === 'th'
                              ? product?.details_paragraph_1_th
                              : product?.details_paragraph_1_en,
                        }}
                        style={
                          i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col
                    xs="12"
                    data-aos="fade-up"
                    style={NON_PADDING_LEFT_RIGHT}
                  >
                    <button
                      style={BUTTON}
                      onClick={() =>
                        navigate(`/product/${product?.product_code}`)
                      }
                    >
                      {t('more_detail')}
                    </button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ProductHighlight;
