import React, { useEffect, useState, CSSProperties } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_TH,
  HEADER_EN,
  BODY_2_TH,
  BODY_2_EN,
  NON_PADDING_LEFT_RIGHT,
  MOBILE_CONTENT_CARD,
  RightImage,
  ImageMobileBox,
} from '../../Layouts/styled/main';
import { color, spacing } from '../../Layouts/theme';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { herb_of_month } from '../../db/herb_of_month';
import { graphql, Link, PageProps } from 'gatsby';
import Img from 'gatsby-image';
import { getSourceImage } from '@/utils/image-sharp';

const IMAGE_STYLE: CSSProperties = {
  backgroundColor: color.mainColor3,
};

const LINK_TO: CSSProperties = {
  textDecoration: 'none',
  color: color.textBlack,
};

interface HerbHightlightProps {
  data?: any;
}

const HerbHightlight: React.FC<HerbHightlightProps> = ({
  data: { herbOfMonth, noImage },
}) => {
  const { t } = useTranslation();
  const [herb, setHerb] = useState<any>({});

  useEffect(() => {
    const mainHerbs = herb_of_month.filter(
      (herb: any) => +herb.herb_of_month_highLight == 1,
    );

    const currentIndex = mainHerbs?.length;
    const randomIndex = Math.floor(Math.random() * currentIndex);
    const prod = mainHerbs[randomIndex];
    setHerb(prod);
  }, []);

  const herbal_name =
    i18next?.language === 'th' ? herb?.herbal_name_th : herb?.herbal_name_en;

  const outstanding_properties =
    i18next?.language === 'th'
      ? herb?.outstanding_properties_th
      : herb?.outstanding_properties_en;

  // const summary_properties =
  //   i18next?.language === 'th'
  //     ? herb?.summary_properties_th
  //     : herb?.summary_properties_en;

  const mapSourceData = herbOfMonth.edges;

  return (
    <>
      <section className="is-not-hd-size">
        {/* //? for screen size xs-md */}
        <Container fluid className="d-md-none d-block">
          <Row className="justify-content-center">
            <Col xs="12">
              <Link
                to={`/product/${herb?.related_product_code_1}`}
                style={LINK_TO}
                state={{
                  productType: herbal_name,
                }}
              >
                <ImageMobileBox
                  data-aos="fade-right"
                  style={{
                    borderRadius: '80px 0px',
                    backgroundColor: color.mainColor3,
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <Img
                    fluid={
                      herb.product_image_path
                        ? getSourceImage(herb.product_image_path, mapSourceData)
                        : noImage.childImageSharp.fluid
                    }
                    alt="herb-of-month"
                    style={{
                      position: undefined,
                    }}
                    imgStyle={{
                      top: '5%',
                      left: '10%',
                      width: '80%',
                      height: '90%',
                    }}
                  />
                </ImageMobileBox>
              </Link>
            </Col>

            <Col xs="10">
              <Container
                fluid
                style={{ paddingTop: 60, alignContent: 'center' }}
              >
                <Row>
                  <Col xs="12" className="text-spacing-l-r">
                    <div data-aos="fade-up">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('herb_of_month_title'),
                        }}
                        style={
                          i18next?.language === 'th'
                            ? MOBILE_HEADER_TH
                            : MOBILE_HEADER_EN
                        }
                      />
                      <br />
                      <div
                        style={
                          i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                        }
                      >
                        <div>
                          {/* <b>{`${t('herd_name')} : `}</b> */}
                          {herbal_name}
                        </div>
                        <div
                          style={{ padding: `${spacing[2]}px ${spacing[0]}px` }}
                        >
                          {/* <b>{`${t('notable_properties')} : `}</b> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: outstanding_properties,
                            }}
                          />
                        </div>
                        {/* <div>{summary_properties}</div> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        {/* //? for screen size md++ */}
        <Container fluid className="d-none d-md-block">
          <Row className="justify-content-center">
            <Col xs="1" />
            <Col md="4">
              <Container
                fluid
                style={{
                  paddingTop: spacing[7],
                  alignContent: 'center',
                  height: '100%',
                }}
              >
                <Row>
                  <Col xs="12">
                    <div data-aos="fade-up">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('herb_of_month_title'),
                        }}
                        style={
                          i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                        }
                      />
                      <br />
                      <div
                        style={
                          i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                        }
                      >
                        <div>
                          {/* <b>{`${t('herd_name')} : `}</b> */}
                          {herbal_name}
                        </div>
                        <div
                          style={{ padding: `${spacing[2]}px ${spacing[0]}px` }}
                        >
                          {/* <b>{`${t('notable_properties')} : `}</b> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: outstanding_properties,
                            }}
                          />
                        </div>
                        {/* <div>{summary_properties}</div> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs="1" />

            <Col
              md="6"
              style={{ paddingRight: spacing[0], overflowX: 'hidden' }}
            >
              <Link
                to={`/product/${herb?.related_product_code_1}`}
                style={LINK_TO}
                state={{
                  productType: herbal_name,
                }}
              >
                <div
                  data-aos="fade-left"
                  style={{ width: '100%', height: 'auto' }}
                >
                  {/* <RightImage
                    src={
                      herb.product_image_path
                        ? require(`../../images/main/${herb.product_image_path}`)
                            .default
                        : require('../../images/product/no-img.png').default
                    }
                    style={IMAGE_STYLE}
                    alt={'herb-of-month'}
                  /> */}
                  <ImageMobileBox
                    style={{
                      borderRadius: '110px 0px',
                      backgroundColor: color.mainColor3,
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Img
                      fluid={
                        herb.product_image_path
                          ? getSourceImage(
                            herb.product_image_path,
                            mapSourceData,
                          )
                          : noImage.childImageSharp.fluid
                      }
                      alt="herb-of-month"
                      style={{ position: undefined }}
                      imgStyle={{
                        top: '5%',
                        left: '10%',
                        width: '80%',
                        height: '90%',
                      }}
                    />
                  </ImageMobileBox>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="hd-size">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs="auto" style={{ width: 480 }}>
              <Container
                fluid
                style={{
                  ...NON_PADDING_LEFT_RIGHT,
                  paddingTop: spacing[7],
                  alignContent: 'center',
                  height: '100%',
                }}
              >
                <Row>
                  <Col xs="auto" style={{ width: 120 }} />
                  <Col
                    xs="auto"
                    style={{ ...NON_PADDING_LEFT_RIGHT, width: 360 }}
                  >
                    <div data-aos="fade-up">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('herb_of_month_title'),
                        }}
                        style={
                          i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                        }
                      />
                      <br />
                      <div
                        style={
                          i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                        }
                      >
                        <div>
                          {/* <b>{`${t('herd_name')} : `}</b> */}
                          {herbal_name}
                        </div>
                        <div
                          style={{ padding: `${spacing[2]}px ${spacing[0]}px` }}
                        >
                          {/* <b>{`${t('notable_properties')} : `}</b> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: outstanding_properties,
                            }}
                          />
                        </div>
                        {/* <div>{summary_properties}</div> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs="auto" style={{ width: 120 }} />
            <Col
              xs="auto"
              data-aos="fade-right"
              style={{ width: 480, height: 480 }}
            >
              <Link
                to={`/product/${herb?.related_product_code_1}`}
                style={LINK_TO}
                state={{
                  productType: herbal_name,
                }}
              >
                {/* <RightImage
                  src={
                    herb.product_image_path
                      ? require(`../../images/main/${herb.product_image_path}`)
                          .default
                      : require('../../images/product/no-img.png').default
                  }
                  style={IMAGE_STYLE}
                  alt={'herb-of-month'}
                /> */}
                <ImageMobileBox
                  style={{
                    borderRadius: '110px 0px',
                    backgroundColor: color.mainColor3,
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <Img
                    fluid={
                      herb.product_image_path
                        ? getSourceImage(herb.product_image_path, mapSourceData)
                        : noImage.childImageSharp.fluid
                    }
                    alt="herb-of-month"
                    style={{ position: undefined }}
                    imgStyle={{
                      top: '5%',
                      left: '10%',
                      width: '80%',
                      height: '90%',
                    }}
                  />
                </ImageMobileBox>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default HerbHightlight;
