// herb_of_month_id = ลำดับ (id)
// herb_of_month_highLight =' ขึ้น HighLight หน้า Home ใส่ "1" เมื่อต้องการขึ้น ขึ้นได้ครั้งละ 4 รายกา'ร
// herbal_name_th = ชื่อสมุนไพร (TH)
// herbal_name_en = ชื่อสมุนไพร (EN)
// outstanding_properties_th = สรรพคุณเด่น (TH)
// outstanding_properties_en = สรรพคุณเด่น (EN)
// summary_properties_th = สรรพคุณย่อ (TH)
// summary_properties_en = สรรพคุณย่อ (EN)
// related_product_code_1 =' รหัสสินค้าเกี่ยวข้อง '1
// related_product_code_2 = รหัสสินค้าเกี่ยวข้อง 2
// related_product_code_3 = รหัสสินค้าเกี่ยวข้อง 3
// related_product_code_4 = รหัสสินค้าเกี่ยวข้อง 4
// background_color = สี BG
// product_image_path = Path รูปสินค้า

export const herb_of_month = [
  {
    herb_of_month_id: '1',
    herb_of_month_highLight: '1',
    herbal_name_th: 'ฟ้าทะลายโจร',
    herbal_name_en: 'Andrographis Paniculata',
    outstanding_properties_th:
      'เป็นสมุนไพรฤทธิ์เย็นที่ใช้ในการดับพิษความร้อน<br/>แก้ไข้ แก้ร้อนใน กระหายน้ำ ลดการอักเสบ<br/>ปัจจุบันมีข้อมูลจากกรมการแพทย์แผนไทยว่า<br/>ในผู้ป่วยที่ได้รับการยืนยันว่าติดเชื้อโควิด-19<br/>ภายใน 72 ชั่วโมงแรกหลังรับประทานฟ้าทะลายโจร<br/>ที่มีสารแอนโดรกราโฟไลต์ วันละ 180 มิลลิกรัม<br/>เป็นเวลา 5 วัน พบว่ามีอาการที่ดีขึ้น',
    outstanding_properties_en:
      'Used to relieve fever, reduce internal body temperature, and reduce inflammation in respiratory system. According to the Thai Ministry of Health, patients infected with COVID-19 who have mild symptoms, when given 180 mg of Andrographolides per day for five days, the patients recover without worsening the symptoms.',
    related_product_code_1: '4010',
    background_color: '#719562',
    product_image_path: 'ouayun_main_herb_1.png',
  },
  {
    herb_of_month_id: '2',
    herb_of_month_highLight: '1',
    herbal_name_th: 'ขมิ้นชัน',
    herbal_name_en: 'Kamin-Chan (Curcuma)',
    outstanding_properties_th:
      'เนื่องจากขมิ้นชันมีสารสำคัญ 2 กลุ่ม ได้แก่ กลุ่มน้ำมันหอมระเหยที่สามารถบรรเทาอาการท้องอืด ท้องเฟ้อ และกลุ่มเคอร์คูมินอยด์(สารสีเหลือง)ที่มีฤทธิ์ลดอักเสบ และกระตุ้นการหลั่งเมือกมาเคลือบกระเพาะได้ดียิ่งขึ้น เหมาะกับผู้ที่กำลังมีปัญหาท้องอืดเฟ้อ มีแผลในกระเพาะ หรือเป็นกรดไหลย้อน',
    outstanding_properties_en:
      'The volatile oils in turmeric relieves flatulence, and the curcuminoids has anti-inflammatory effects, stimulates stomach mucus to reduce the burning effect, suitable for those with flatulence, stomach ulcers, and patients with heartburns.',
    related_product_code_1: '4003',
    background_color: '#719562',
    product_image_path: 'ouayun_main_herb_2.png',
  },
  {
    herb_of_month_id: '3',
    herb_of_month_highLight: '1',
    herbal_name_th: 'บิลเบอร์รี่และดอกดาวเรือง',
    herbal_name_en: 'Bilberry&Marigold flower',
    outstanding_properties_th:
      'บิลเบอร์รี่เป็นราชาแห่งการบำรุงดวงตา จากสารแอนโธไซยาโนไซต์ แทนนินเมื่อรวมตัวกับดอกดาวเรืองที่มีสารลูทีน ซีแซนทีนจึงช่วยถนอมสายตาทำให้ดวงตาแข็งแรง ช่วยในเรื่องการมองเห็นทำให้สายตาดีขึ้นทั้งกลางวัน และกลางคืน เพิ่มสีจอประสาทตาทำให้สายตาสามารถทนต่อแสงได้มากขึ้น\n สามารถบรรเทาอาการอักเสบของดวงตา และบำรุงเพื่อช่วยป้องกันโรคที่อาจจะเกิดกับดวงตาได้ ทั้งโรคต้อกระจก ต้อเนื้อ ต้อหิน และยังช่วยป้องกันกระจกตา และจอประสาทตาไม่ให้เสื่อมก่อนเวลา',
    outstanding_properties_en:
      'Bilberry contains powerful antioxidants named anthocyanins and tannins. Marigold flower contains lutein and zeaxanthin. Combined the two herbs together, it is suitable for those with blurred visions, annd abnormal night visions.',
    related_product_code_1: '2014',
    background_color: '#6f965f',
    product_image_path: 'ouayun_main_herb_3.png',
  },
  {
    herb_of_month_id: '4',
    herb_of_month_highLight: '1',
    herbal_name_th: 'งาดำ',
    herbal_name_en: 'Black Sesame',
    outstanding_properties_th:
      'ใช้ในการรักษาผู้ป่วยข้อเข่าเสื่อม โดยเซซามินสามารถไปยับยั้งการทำงานของ Interleukin-1-beta ซึ่งเป็นตัวเร่งให้เกิดการทำลายของกระดูกอ่อน ทำให้ข้อเข่าเสื่อม การใช้งานอื่นๆ ลดระดับคอลเลสเตอรอล ลดความดันโลหิต ลดอัตราเสี่ยงโรคหัวใจ ป้องกันกระดูกพรุน',
    outstanding_properties_en:
      'Used to reduce the symptoms related with arthritis, such as joint and knee pains. Sesamin stops the Interleukin-1-Beta, a catalyst that is harmful to the cartilage. Research also show high correlation of sesamin intake with lowering cholesetrol, reducing risk of cardiovascular disease, and reducing blood pressure.',
    related_product_code_1: '2051',
    background_color: '#719562',
    product_image_path: 'ouayun_main_herb_4.png',
  },
  {
    herb_of_month_id: '5',
    herb_of_month_highLight: '1',
    herbal_name_th: 'กระชาย',
    herbal_name_en: 'Finger root',
    outstanding_properties_th:
      'ช่วยปรับสมดุลของความดันโลหิตในร่างกาย ช่วยลดความดันโลหิตเมื่อความดันโลหิตสูง แต่เมื่อความดันโลหิตต่ำก็จะช่วยทำให้ความดันเพิ่มขึ้นจนเป็นปกติ และยังช่วยรักษาฝ้าขาวในปาก มีงานวิจัยพบว่าสารสกัดจากกระชายสามารถช่วยต้านการเสื่อมของกระดูกอ่อนในหลอดทดลองได้ งานวิจัยพบว่าสารสกัดคลอโรฟอร์มและเมทานอลจากรากของกระชายมีฤทธิ์ในการต้านการเจริญเติบโตของเชื้อ Giardia intestinalis ซึ่งเป็นพยาธิเซลล์เดียวในลำไส้ที่ก่อให้เกิดภาวะท้องเสีย ซึ่งเป็นปัญหาที่สำคัญอย่างมากสำหรับผู้ป่วยที่เป็นโรคภูมิคุ้มกันบกพร่อง',
    outstanding_properties_en:
      'Regulates the blood pressure in the body, regardless if it is high or low. The faculty of pharmarcy in Thammasat and Chiangmai University finds that finger root extract intake significantly increases the immune system.',
    related_product_code_1: '2058',
    background_color: '#d3a98b',
    product_image_path: 'ouayun_main_herb_5.png',
  },
  {
    herb_of_month_id: '6',
    herb_of_month_highLight: '1',
    herbal_name_th: 'เห็ดหลินจือ',
    herbal_name_en: 'Lingzhi',
    outstanding_properties_th:
      'เห็ดหลินจือมีสารสำคัญที่มีประโยชน์ต่อร่างกายมากมายและสารเหล่านั้นเป็นสารต้านอนุมูลอิสระและเป็นสารที่ช่วยในการต้านสารพิษในร่างกาย ที่เป็นปัจจัยสำคัญทำให้เกิดอาการเจ็บป่วยและโรคภูมิแพ้ และยังช่วยรักษาโรคภูมิแพ้ หอบหืดต่างๆ โดยเฉพาะ เห็ดหลินจือมีสารเบต้า-ดี กลูแคน (Beta-D-Glucan) ซึ่งเป็นสารโพลีแซคคาไรด์ (Polysaccharide) มีคุณสมบัติในการช่วยกระตุ้นภูมิคุ้มกันของร่างกาย สร้างภูมิคุ้มกันโรค ช่วยลดความดันโลหิต ลดระดับน้ำตาลในเลือด ใช้รักษาผู้ป่วยโรคภูมิแพ้ และบำรุงร่างกาย',
    outstanding_properties_en:
      'Lingzhi contains many powerful antioxidants, especially those with allergy problems, asthma. The beta-d-glucan stimulates the immune system, reduces blood pressure',
    related_product_code_1: '2037',
    background_color: '#d3a98b',
    product_image_path: 'ouayun_main_herb_6.png',
  },
  {
    herb_of_month_id: '7',
    herb_of_month_highLight: '1',
    herbal_name_th: 'กระชายดำ งาดำ ดอกคำฝอย',
    herbal_name_en: 'Kaempferia parviflora , Sesame ,Safflower',
    outstanding_properties_th:
      'กระชายดำ มีฤทธิ์ช่วยเพิ่มการไหลเวียนของเลือด ป้องกันโรคหลอดเลือดสมองอุดตัน ลดอาการอักเสบของกล้ามเนื้อ บำรุงกำลัง ขับลมในลำไส้ จากงานวิจัยมหาวิทยาลัยขอนแก่นพบว่ากระชายดำเพิ่มอัตราการใช้ออกซิเจนขณะออกกำลังกายแบบแอโรบิก ลดภาวะเครียดที่เกิดจาก oxidation ช่วยป้องกันเซลล์ต่างๆให้เสื่อมช้าลง<br/>\n<br/>\nเซซามิน สามารถลดการอักเสบในข้อ เพิ่มปริมาณกระดูกอ่อน คอลลาเจนในข้อต่อ และยังสามารถป้องกันอาการข้อเข่าเสื่อมได้<br/>\n<br/>\n ดอกคำฝอย ช่วยลดไขมันในเส้นเลือด ป้องกันไขมันอุดตันในเส้นเลือด เนื่องจากดอกคำฝอยมีกรดไลโนเลอิก (Linoleic Acid) อยู่มาก ซึ่งกรดชนิดนี้จะเข้าไปทำปฏิกิริยากับไขมันในเลือดและขับออกทางปัสสาวะและทางอุจจาระ',
    outstanding_properties_en:
      'Stimulates blood flow in the body. Research finds positive correlation with the Kaempferia intake with reduced risk of stroke, muscle inflammation. During aerobic exercise, Kaempferia reduces oxidative stress and increases sports performance and enhances the rate of recovery.',
    related_product_code_1: '4012',
    background_color: '#719563',
    product_image_path: 'ouayun_main_herb_7.png',
  },
  {
    herb_of_month_id: '8',
    herb_of_month_highLight: '1',
    herbal_name_th: 'เอลเดอร์เบอร์รี่',
    herbal_name_en: 'Elderberry',
    outstanding_properties_th:
      'มีสารแอนโทไซยานิน (Anthocyanins) ยับยั้งการเกาะติดของเชื้อไวรัส<br/>และยังมีวิตามิน B 1 ,6 ,12 ช่วยในการดูแลระบบประสาทและสมอง เสริมการทำงานของเม็ดเลือดขาว<br/>\nอุดมด้วยวิตามิน C ช่วยเสริมสร้างภูมิคุ้มกันต่อต้านอนุมูลอิสระ และช่วยในการสังเคราะห์คอลลาเจน ลดการอักเสบของผิว',
    outstanding_properties_en:
      'In some European research, Elderberry can stimulates white blood cells to react against some viruses, therefore increases the body immune system. Vitamin C in Elderberry also increases immunity, has anti-oxidants effects, and encourages collagen production.',
    related_product_code_1: '3008',
    background_color: '#608385',
    product_image_path: 'ouayun_main_herb_8.png',
  },
  {
    herb_of_month_id: '9',
    herb_of_month_highLight: '1',
    herbal_name_th: 'มะขามป้อม',
    herbal_name_en: 'Emblica (gooseberry)',
    outstanding_properties_th:
      'เป็นสมุนไพรที่อยู่ในตำรับตรีผลา ซึ่งสามารถลดระดับไขมันในเลือด ลดอาการไอ ขับเสมหะ และลดระดับน้ำตาลในเลือดได้ โดยมีประสิทธิภาพในการลดระดับไขมันในเลือดเทียบเท่ายา pravastatin',
    outstanding_properties_en:
      'A potent anti-oxidant commonly used in Ayuravedic and Thai traditional medicine to reduce cholesterol, relieves expectorant, and reduces blood sugar.',
    related_product_code_1: '4006',
    background_color: '#748055',
    product_image_path: 'ouayun_main_herb_9.png',
  },
  {
    herb_of_month_id: '10',
    herb_of_month_highLight: '1',
    herbal_name_th: 'สาหร่ายสีแดง',
    herbal_name_en: 'Red algae',
    outstanding_properties_th:
      'สาหร่ายที่มีประโยชน์ต่อสุขภาพและความงาม มีสารสำคัญที่หลายคนรู้จัก นั่นคือ “แอสตาแซนทิน (Astaxanthin)” เป็นสารต้านอนุมูลอิสระ ช่วยต่อต้านอนุมูลอิสระได้มากกว่าสารต้านอนุมูลอิสระหลายชนิด เช่น วิตามินซี วิตามินอี เบต้าแคโรทีน ซีลีเนียม ช่วยชลอการเสื่อมของเซลล์ บำรุงผิวและดวงตา บำรุงสมองและประสาทส่วนกลาง',
    outstanding_properties_en:
      'Astaxanthin from Haemotococcus Pluviaris is a super-antioxidant more potent than many vitamins and super fruits. It has anti-aging properties especially to the skin, improves vascular health and nervous system.',
    related_product_code_1: '2021',
    background_color: '#cea49a',
    product_image_path: 'ouayun_main_herb_10.png',
  },
  {
    herb_of_month_id: '11',
    herb_of_month_highLight: '1',
    herbal_name_th: 'เขากุย',
    herbal_name_en: 'Khao Kui',
    outstanding_properties_th:
      'ลดไข้ ตัวร้อน : ช่วยลดอาการตัวร้อน ตัวยาจะมีฤทธิ์เย็นที่ช่วยลดไข้ ลดความร้อนภายในร่างกายได้ดี  แก้ร้อนใน : อาการแผลร้อนใน ข้างในปาก มักเกิดจากนอนดึก พักผ่อนน้อย ดื่มน้ำน้อยหรือทานอาหารบางชนิด เช่น ทุเรียน กินอาหารรสเผ็ด ร้อนจัด',
    outstanding_properties_en:
      'A traditional Chinese formula to relieve internal body heat, reduces oral ulcers usually associated with dehydration, lack of rest, and high consumption of  junk food and "hot" fruits such as durians.',
    related_product_code_1: '4007',
    background_color: '#8ca5ab',
    product_image_path: 'ouayun_main_herb_11.png',
  },
];
